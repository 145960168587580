import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Courses from "./components/Courses/Courses";
import AboutUs from "./components/AboutUs/AboutUs";
import Footer from "./components/Footer/Footer";
import Calendar from "./components/Calendar/Calendar";
import TutorInfo from "./components/TutorInfo/TutorInfo";
import Students from "./components/Students/Students";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/register";
import { AuthProvider } from "./contexts/authContext";
import ProtectedRoute from "./components/Routing/ProtectedRoute";
import Unauthorized from "./components/Unauthorized/Unauthorized";
import TutorsList from "./components/TutorList/TutorList";
import Settings from "./components/Settings/Settings";
import Privacy from "./components/Privacy/Privacy";
import MembershipOptions from "./components/Membership/Membership";
import TutorProfile from "./components/TutorProfile/TutorProfile";
import { useEffect, useState } from "react";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";


const App = () => {
  const [tutors, setTutors] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchTutors = async () => {
      try {
        const tutorsCollection = collection(db, "users");
        const q = query(tutorsCollection, where("role", "==", "tutor"));
        const snapshot = await getDocs(q);
        const tutorList = snapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setTutors(tutorList);
      } catch (error) {
        console.error("Error fetching tutors: ", error);
      }
    };

    fetchTutors();
  }, [db]);
  return (
    <Router>
      <AuthProvider>
        <div>
          <Header />
          <div className="underheader">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Main />
                    <AboutUs />
                    <Courses />
                    {/* <Calendar2 /> */}
                  </>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/tutors" element={<TutorsList />} />
              <Route path="/tutor/:tutorId" element={<TutorProfile tutors={tutors} />} />

              <Route
                path="/calendar"
                element={
                  <ProtectedRoute
                    element={<Calendar />}
                    allowedRoles={['student', 'tutor', 'admin']}
                  />
                }
              />
              <Route
                path="/tutorinfo"
                element={
                  <ProtectedRoute
                    element={<TutorInfo />}
                    allowedRoles={['student', 'admin']}
                  />
                }
              />
              <Route
                path="/students"
                element={
                  <ProtectedRoute
                    element={<Students />}
                    allowedRoles={['tutor', 'admin']}
                  />
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute
                    element={<Settings />}
                    allowedRoles={['tutor', 'admin', "student"]}
                  />
                }
              />
              <Route
                path="/membership"
                element={
                  <ProtectedRoute
                    element={<MembershipOptions />}
                    allowedRoles={['tutor', 'admin', 'student']}
                  />
                }
              />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/privacy" element={<Privacy />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
};

export default App;
