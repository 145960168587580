import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './TutorProfile.css';

/*MOCK INFO*/
const mockAvailability = "Monday to Friday: 9 AM - 6 PM | Saturday: 10 AM - 2 PM";
const mockReviews = [
    {
        studentName: "John Doe",
        comment: "Amazing tutor! Helped me understand Spanish so much better.",
        rating: 5,
    },
    {
        studentName: "Jane Smith",
        comment: "Great lessons, very patient and knowledgeable.",
        rating: 4.5,
    },
    {
        studentName: "Carlos Méndez",
        comment: "Highly recommend! The lessons are fun and interactive.",
        rating: 5,
    },
];

const TutorProfile = ({ tutors }) => {
    const navigate = useNavigate();
    const { tutorId } = useParams();
    const tutor = tutors.find((tutor) => tutor.uid === tutorId);

    if (!tutor) {
        return <p>Tutor not found. Please go back to the tutor list.</p>;
    }

    return (
        <div className="tutor-profile-page">
            <div className="tutor-profile-container">
                {/* Left Section */}
                <div className="tutor-profile-left">
                    <div className="tutor-profile-header">
                        <div className="tutor-profile-pic">
                            <img
                                src="/img/profilepic1.jpg"
                                alt={`${tutor.tutorname} profile`}
                                className="tutor-profile-pic"
                            />
                        </div>
                        <div className="tutor-profile-details">
                            <h2 className="tutor-profile-name">{tutor.tutorname}</h2>
                            <div className="tutor-profile-meta">
                                <p className="tutor-profile-country"><strong>🌍 Country:</strong> {tutor.country || "Not provided"}</p>
                                <p className='tutor-profile-language'><strong>🌐 Speaks:</strong> {tutor.language || "Not provided"}</p>
                                <p><strong>👶 Ages:</strong> {tutor.teachingAges?.join(", ") || "Not specified"}</p>                         
                                <p><strong>📚 Ideal for levels:</strong> {tutor.teachingLevels?.join(", ") || "Not specified"}</p>
                            </div>
                        </div>
                    </div>


                    <div className="tutor-profile-content">
                        <div className="tutor-profile-description-section">
                            <h3>About Me</h3>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: (tutor.profileDescription || "This tutor hasn't provided a description yet.")
                                        .replace(/\n/g, '<br />'),
                                }}
                            />
                        </div>


                        <div className="tutor-profile-availability-section">
                            <h3>Calendar Availability</h3>
                            <p>{mockAvailability || "Mock availability data goes here."}</p>
                        </div>

                        <div className="tutor-profile-reviews-section">
                            <h3>Student Reviews</h3>
                            {mockReviews.length > 0 ? (
                                <ul>
                                    {mockReviews.map((review, index) => (
                                        <li key={index}>
                                            <p><strong>{review.studentName}</strong>:</p>
                                            <p>"{review.comment}"</p>
                                            <p>Rating: ⭐ {review.rating}</p>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No reviews yet.</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Right Sticky Section */}
                <div className="tutor-profile-right">
                    <div className="tutor-profile-sticky">
                        {/* Video at the top of the sticky section */}
                        <div className="tutor-profile-video">
                            <iframe
                                sandbox="allow-scripts allow-same-origin allow-presentation"
                                src="https://www.youtube.com/embed/OXR-u-i_WQQ"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Tutor Introduction Video"
                            ></iframe>
                        </div>

                        <p className="tutor-profile-price">${tutor.price || "0"} / h</p>
                        <button className="tutor-profile-book-button">Book Trial Lesson</button>
                        <p>⭐ {tutor.rating || "5"} ({mockReviews.length} reviews)</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TutorProfile;
