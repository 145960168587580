import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import moment from "moment-timezone";

const db = getFirestore();

export const HOURS_START = 0;
export const HOURS_END = 24;
export const TIME_SLOT_DURATION = 30;

export const getFirestoreData = async () => {
  console.log("Fetching Firestore documents...");

  const tutorUID = localStorage.getItem("tutorUID");
  const studentUID = localStorage.getItem("studentUID");

  if (!tutorUID || !studentUID) {
    throw new Error("tutorUID or studentUID not found in localStorage");
  }

  const [
    tutorDoc,
    studentDoc,
    tutorStudentDoc,
    studentTutorDoc,
    tutorStudentsSnapshot,
  ] = await Promise.all([
    getDoc(doc(db, "users", tutorUID)),
    getDoc(doc(db, "users", studentUID)),
    getDoc(doc(db, "users", tutorUID, "students", studentUID)),
    getDoc(doc(db, "users", studentUID, "tutors", tutorUID)),
    getDocs(collection(db, `users/${tutorUID}/students`)),
  ]);

  return {
    tutor: tutorDoc.data(),
    student: studentDoc.data(),
    tutorStudent: tutorStudentDoc.data(),
    studentTutor: studentTutorDoc.data(),
    tutorStudents: tutorStudentsSnapshot.docs.map((doc) => doc.data()),
  };
};

export const requestAccessTokenWithRefreshToken = async (
  clientId,
  clientSecret,
  refreshToken
) => {
  if (!clientId || !clientSecret || !refreshToken) {
    console.error("Missing required credentials for tutor token request");
    return;
  }

  const tokenUrl = "https://oauth2.googleapis.com/token";
  const body = new URLSearchParams({
    client_id: clientId,
    client_secret: clientSecret,
    refresh_token: refreshToken,
    grant_type: "refresh_token",
  });

  const response = await fetch(tokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body.toString(),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error || "Failed to obtain tutor access token");
  }

  console.log("Tutor access token obtained successfully:", data.access_token);
  return data.access_token;
};

export const fetchBlockedSlots = async (
  accessToken,
  currentWeek,
  userTimeZone,
  calendarIds
) => {
  // Ensure currentWeek is a valid moment object
  const weekStart = moment(currentWeek).isValid()
    ? moment(currentWeek).startOf("isoWeek").tz(userTimeZone)
    : null;
  const weekEnd = moment(currentWeek).isValid()
    ? moment(currentWeek).endOf("isoWeek").tz(userTimeZone)
    : null;

  const startOfWeek = weekStart ? weekStart.toISOString() : null;
  const endOfWeek = weekEnd ? weekEnd.toISOString() : null;

  let allBlockedSlots = [];

  try {
    const fetchPromises = calendarIds.map(async (calendarId) => {
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${startOfWeek}&timeMax=${endOfWeek}&singleEvents=true&orderBy=startTime`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      const blockedSlots = data.items.map((event) => ({
        start: moment(event.start.dateTime || event.start.date)
          .tz(userTimeZone)
          .format(),
        end: moment(event.end.dateTime || event.end.date)
          .tz(userTimeZone)
          .format(),
      }));
      return blockedSlots;
    });

    allBlockedSlots = await Promise.all(fetchPromises);
    allBlockedSlots = [].concat(...allBlockedSlots); // Flatten the array
  } catch (error) {
    console.error("Error fetching blocked slots:", error);
  }
  return allBlockedSlots;
};

export const calculateAvailability = async (
  currentWeek,
  tutorTimeZone,
  tutorWorkingHoursStart,
  tutorWorkingHoursEnd,
  students,
  blockedSlots
) => {
  if (
    !tutorTimeZone ||
    tutorWorkingHoursStart === undefined ||
    tutorWorkingHoursEnd === undefined
  ) {
    throw new Error("Tutor configuration not properly loaded");
  }

  // Get scheduled classes from all students
  const scheduledClasses = students.flatMap(
    (student) => student.scheduledClasses || []
  );

  let slots = new Map();

  // Generate time slots
  for (let day = 0; day < 7; day++) {
    for (let hour = HOURS_START; hour < HOURS_END; hour++) {
      for (let minute = 0; minute < 60; minute += TIME_SLOT_DURATION) {
        const slotStart = moment(currentWeek)
          .add(day, "days")
          .set({ hour, minute });
        const slotEnd = moment(slotStart).add(TIME_SLOT_DURATION, "minutes");

        // Convert to tutor's timezone for working hours check
        const tutorDayStart = moment(slotStart)
          .tz(tutorTimeZone)
          .set("hour", tutorWorkingHoursStart)
          .set("minute", 0);
        const tutorDayEnd = moment(slotStart)
          .tz(tutorTimeZone)
          .set("hour", tutorWorkingHoursEnd)
          .set("minute", 0);

        // Check various conditions
        const isOutsideWorkingHours =
          slotStart.isBefore(tutorDayStart) || slotEnd.isAfter(tutorDayEnd);

        const isBlocked = blockedSlots.some(
          (slot) =>
            moment(slot.start).isBefore(slotEnd) &&
            moment(slot.end).isAfter(slotStart)
        );

        const isScheduledClass = scheduledClasses.some(
          (classSlot) =>
            moment(classSlot.start).isBefore(slotEnd) &&
            moment(classSlot.end).isAfter(slotStart)
        );

        slots.set(slotStart.format(), {
          isAvailable: !isOutsideWorkingHours,
          isBlocked: isBlocked || isScheduledClass,
        });
      }
    }
  }

  return slots;
};

export const verifySelectedSlot = (selectedSlot, currentWeek) => {
  if (!selectedSlot) {
    alert("Please select a valid time slot.");
    return false;
  } else {
    const endSlot = moment(selectedSlot.start).add(1, "hours");
    console.log("End slot: ", endSlot.format()); // Verifica el valor de endSlot
    const isWithinValidRange = endSlot.isBefore(
      moment(currentWeek).add(7, "days").set({ hour: HOURS_END })
    );
    console.log("Is within valid range:", isWithinValidRange); // Verifica si está dentro del rango
    return isWithinValidRange;
  }
};

export const createFirestorePathForTrialClass = async (
  studentUID,
  tutorUID
) => {
  // Asegúrate de que studentUID y tutorUID tengan los valores correctos
  if (!studentUID || !tutorUID) {
    throw new Error("studentUID o tutorUID no están definidos");
  }

  // Crea la ruta en Firestore para el estudiante con datos iniciales
  const studentDocRef = doc(db, "users", studentUID, "tutors", tutorUID);
  await setDoc(
    studentDocRef,
    {
      credits: 0,
      cost: 0,
      scheduledClasses: [],
    },
    { merge: true }
  );
  console.log("Ruta creada en Firestore para el estudiante.");

  // Crea la ruta en Firestore para el tutor con datos iniciales
  const tutorDocRef = doc(db, "users", tutorUID, "students", studentUID);
  await setDoc(
    tutorDocRef,
    {
      credits: 0,
      cost: 0,
      scheduledClasses: [],
    },
    { merge: true }
  );
  console.log("Ruta creada en Firestore para el tutor.");
};

export const createClassFirestore = async (studentUID, tutorUID, classData) => {
  // Actualizar Firestore para el tutor
  await updateDoc(doc(db, "users", studentUID, "tutors", tutorUID), {
    scheduledClasses: arrayUnion(classData),
  });

  // Actualizar Firestore para el estudiante en la ruta del tutor
  updateDoc(doc(db, "users", tutorUID, "students", studentUID), {
    scheduledClasses: arrayUnion(classData),
  });
};

export const createGoogleCalendarEvent = async (
  clientId,
  clientSecret,
  tutorAccessToken,
  student,
  selectedSlot,
  userTimeZone
) => {
  const studentName = student.name || "Unknown Student";
  console.log("Student Name:", studentName);

  // Definir `endSlot` basado en el `selectedSlot`
  const endSlot = moment(selectedSlot.start).add(1, "hours"); // Añadir 1 hora al horario seleccionado

  const event = {
    summary: `${studentName ? studentName : "UserERROR"} LearnLantern Class`,
    start: {
      dateTime: selectedSlot.start.format(),
      timeZone: userTimeZone,
    },
    end: {
      dateTime: endSlot.format(), // Usar `endSlot` aquí
      timeZone: userTimeZone,
    },
  };

  // Crear el evento para el tutor
  const response = await fetch(
    `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tutorAccessToken}`, // Token de acceso del tutor
        "Content-Type": "application/json",
      },
      body: JSON.stringify(event),
    }
  );

  let eventId = null;
  let studentEventId = null;

  if (response.ok) {
    const eventData = await response.json();
    eventId = eventData.id;
    console.log(
      "Google Calendar event created successfully for the tutor. Event ID:",
      eventId
    );

    // Crear el evento en el calendario del estudiante
    let studentAccessToken = localStorage.getItem(
      "google_student_access_token"
    );
    if (!studentAccessToken) {
      studentAccessToken = await requestAccessTokenWithRefreshToken(
        clientId,
        clientSecret,
        student.REFRESH_TOKEN
      );
    }

    const studentCalendarResponse = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${studentAccessToken}`, // Token de acceso del estudiante
          "Content-Type": "application/json",
        },
        body: JSON.stringify(event),
      }
    );

    if (studentCalendarResponse.ok) {
      const studentEventData = await studentCalendarResponse.json();
      studentEventId = studentEventData.id;
      console.log(
        "Google Calendar event created successfully for the student. Event ID:",
        studentEventId
      );
    } else {
      console.warn(
        "Failed to book the event in Google Calendar for the student."
      );
    }
  } else {
    console.warn("Failed to book the event in Google Calendar for the tutor.");
  }

  return {
    tutorEventId: eventId,
    studentEventId: studentEventId,
  };
};

export const updateGoogleCalendarEventIds = async (
  tutorEventId,
  studentEventId,
  studentUID,
  tutorUID,
  classData
) => {
  // Actualizamos el documento del estudiante
  const studentRef = doc(db, "users", studentUID, "tutors", tutorUID);
  const studentSnapshot = await getDoc(studentRef);
  const studentData = studentSnapshot.data();
  let studentScheduledClasses = studentData.scheduledClasses || [];

  // Actualizamos el eventId y studentEventId en la clase correcta
  studentScheduledClasses = studentScheduledClasses.map((cls) => {
    if (cls.start === classData.start) {
      return {
        ...cls,
        tutorEventId: tutorEventId, // Actualizar el eventId
        studentEventId: studentEventId, // Actualizar studentEventId
      };
    }
    return cls;
  });

  // Actualizamos Firestore con las clases modificadas
  console.log(
    "Actualizando Firestore para el estudiante con clases actualizadas:",
    studentScheduledClasses
  );
  await updateDoc(studentRef, {
    scheduledClasses: studentScheduledClasses,
  });
  console.log("Event ID actualizado exitosamente para el estudiante.");

  // Ahora hacemos lo mismo para el documento del tutor
  const tutorRef = doc(db, "users", tutorUID, "students", studentUID);
  const tutorSnapshot = await getDoc(tutorRef);
  const tutorData = tutorSnapshot.data();
  let tutorScheduledClasses = tutorData.scheduledClasses || [];

  // Actualizamos el eventId y studentEventId en la clase correcta
  tutorScheduledClasses = tutorScheduledClasses.map((cls) => {
    if (cls.start === classData.start) {
      return {
        ...cls,
        tutorEventId: tutorEventId, // Actualizar el eventId
        studentEventId: studentEventId, // Actualizar studentEventId
      };
    }
    return cls;
  });

  // Actualizamos Firestore con las clases modificadas
  console.log(
    "Actualizando Firestore para el tutor con clases actualizadas:",
    tutorScheduledClasses
  );
  await updateDoc(tutorRef, {
    scheduledClasses: tutorScheduledClasses,
  });
  console.log("Event ID actualizado exitosamente para el tutor.");
};

export const CheckCredits = async (studentTutor) => {
  const currentCredits = studentTutor.credits || 0; // Safely assign credits
  console.log("Current credits:", currentCredits);
  if (currentCredits <= 0) {
    console.warn("No credits available to decrement.");
    alert("This student does not have enough credits to book the class.");
    return false; // Stop the process if no credits are available
  }
  return true;
};

export const decrementCredits = async (studenTutor, studentUID, tutorUID) => {
  let currentCredits = studenTutor.credits || 0; // Asegúrate de que `credits` exista

  if (currentCredits <= 0) {
    console.warn("No credits available to decrement.");
    alert(
      "Este estudiante no tiene suficientes créditos para reservar la clase."
    );
    return false;
  }

  // Decrementar el crédito en 1
  await updateDoc(doc(db, "users", studentUID, "tutors", tutorUID), {
    credits: currentCredits - 1,
  });
  console.log("Crédito decrementado en 1.");
  return true;
};

export const alreadyTookTrial = async (studentUID) => {
  const studentRef = doc(db, "users", studentUID);
  const studentSnapshot = await getDoc(studentRef);
  const studentData = studentSnapshot.data();
  if (studentData.alreadytooktrial) {
    return true;
  }
  return false;
};

const deleteGoogleCalendarEvent = async (tutorEventId, studentEventId) => {
  // Obtener el evento correcto del tutor y estudiante basado en la fecha

  // Eliminar evento del calendario del tutor
  const deleteTutorEventResponse = await fetch(
    `https://www.googleapis.com/calendar/v3/calendars/primary/events/${tutorEventId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`, // Token de acceso del tutor
      },
    }
  );

  if (deleteTutorEventResponse.ok) {
    console.log(
      "Evento de Google Calendar eliminado correctamente para el tutor."
    );

    // Eliminar evento del calendario del estudiante
    let studentAccessToken = localStorage.getItem(
      "google_student_access_token"
    );
    if (!studentAccessToken) {
      studentAccessToken = await requestStudentAccessTokenWithRefreshToken(
        studentUID
      );
    }

    const deleteStudentEventResponse = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/primary/events/${studentEventId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${studentAccessToken}`, // Token de acceso del estudiante
        },
      }
    );

    if (deleteStudentEventResponse.ok) {
      console.log(
        "Evento de Google Calendar eliminado correctamente para el estudiante."
      );
    } else {
      console.warn(
        "Fallo al eliminar el evento de Google Calendar para el estudiante."
      );
    }
  } else {
    console.warn(
      "Fallo al eliminar el evento de Google Calendar para el tutor."
    );
  }
};
